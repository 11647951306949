<template>
  <Box>

    <ErrorRow :error="error" />
    <SuccessRow :message="message" />

    <Row>
      <Column :width="9" :showBorder="true">

        <Box class="rc-text-medium rc-text-label">
          <Row>
            <Column>
              {{ catelog.get(catelogKeys.FILTERS.TITLE) }}
            </Column>
          </Row>

          <Row>
            <div class="rc-box-col-2 rc-text-middle">
              {{ catelog.get(catelogKeys.FILTERS.STATUS) }}
            </div>

            <div class="rc-box-col-2">
              <Button v-on:click="setFilterState('all')" :disabled="isFilterState('all')">
                All
              </Button>
            </div>
            <div class="rc-box-col-2">
              <Button v-on:click="setFilterState('active')" :disabled="isFilterState('active')">
                Active
              </Button>
            </div>
            <div class="rc-box-col-2">
              <Button v-on:click="setFilterState('inactive')" :disabled="isFilterState('inactive')">
                Inactive
              </Button>
            </div>
            <div class="rc-box-col-2">
              <Button v-on:click="setFilterState('invited')" :disabled="isFilterState('invited')">
                Invited
              </Button>
            </div>
            <div class="rc-box-col-2">
              <Button v-on:click="setFilterState('notinvited')" :disabled="isFilterState('notinvited')">
                Not invited
              </Button>
            </div>
            <div class="rc-box-col-3" />
          </Row>

          <Row>
            <div class="rc-box-col-2 rc-font-medium">
              Search
            </div>
            <div class="rc-box-col-5">
              <Box>
                <KeyValue title="search" :clearValue="clear" v-on:enter="startSearch" v-on:value="searchValue" />
              </Box>
            </div>
            <div class="rc-box-col-1">
              <Button v-on:click="startSearch" :disabled="isLoading">
                Go
              </Button>
            </div>

            <div class="rc-box-col-2">
              <Button v-on:click="clearSearch" :disabled="isLoading">
                Clear
              </Button>
            </div>
            <div class="rc-box-col-5" />
          </Row>

        </Box>

      </Column>

      <Column :width="6" :showBorder="true">
        <Box class="rc-text-medium rc-text-label">

          <Row>
            <Column>
              {{ catelog.get(catelogKeys.ACTIONS.TITLE) }}
            </Column>
          </Row>

          <Row>
            <div class="rc-box-col-6" />

            <div v-if="hasFeatureSso && !company.isNew()" class="rc-box-col-3">
              <Button v-on:click="add" :go="true" :disabled="false">
                Add User
              </Button>
            </div>
            <div v-else class="rc-box-col-3" />

            <div v-if="!hasFeatureSso && !company.isNew()" class="rc-box-col-3">
              <Button v-on:click="invite" :go="true" :disabled="false">
                Invite User
              </Button>
            </div>
            <div v-else class="rc-box-col-3" />

            <div class="rc-box-col-3">
              <Button v-on:click="refresh" :disabled="isLoading" :key="'refreshButton' + isLoading">
                Refresh
              </Button>
            </div>
          </Row>

        </Box>

      </Column> <!-- col-6 -->
    </Row>

    <TitleRow>
      <Column :width="15">
        <EmployeeDetailsHeader />
      </Column>
    </TitleRow>

    <Row :showBorder="true"> <!-- row2 -->
      <Column :width="15">
        <Box>

          <div v-for="(user, index) in users" :key="user['@rid']" :class="isEven(index) ? 'rowEven row' : 'rowOdd row'"
            style="padding: 8px">
            <Column :width="15">
              <EmployeeDetails :userData="user" :isAdmin="isAdmin" />
              <Box>
                <Menu :isAdmin="isAdmin" :isAdminList="isAdminList" :userId="employeeId(user)" page="list"
                  v-on:action='runAction' />
              </Box>
            </Column>
          </div>

        </Box> <!-- /box-15 -->
      </Column> <!-- /row2 -->
    </Row>

    <LoadingRow :showBorder="true" v-if="isLoading">{{ loadingMessage }} </LoadingRow>

    <Row :showBorder="true" v-if="!isLoading">
      <div class="rc-box-col-4" />
      <div class="rc-box-col-1">
        <Button v-if="canPreviousPage()" v-on:click="previousPage"> &lt; </Button>
      </div>

      <div class="rc-box-col-5 rc-text-centre rc-text-label">
        Showing {{ min }} to {{ max }} of {{ total }}
      </div>

      <div class="rc-box-col-1">
        <Button v-if="canNextPage()" v-on:click="nextPage"> &gt; </Button>
      </div>
      <div class="rc-box-col-4" />
    </Row>

    <ProgressRow :display="displayProgress" title="Sending" />

    <ConfirmRemove @closeConfirmed="closeConfirmed" @closeRejected="closeRejected" v-if="showConfirmRemove"
      v-bind:user="this.removeEmployee" />

    <ConfirmRestore @closeConfirmed="closeConfirmed" @closeRejected="closeRejected" v-if="showConfirmRestore"
      v-bind:user="this.removeEmployee" />

  </Box>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import ConstUtils from '@/utils/ConstUtils.js';
import ConnectionUtils from '@/utils/ConnectionUtils.js';
import ContentUtils from '@/utils/ContentUtils.js';
import StringUtils from '@/utils/StringUtils.js';

import ErrorRow from '@/components/row/ErrorRow.vue';
import SuccessRow from "@/components/row/SuccessRow.vue";
import ProgressRow from '@/components/row/ProgressRow.vue'

import KeyValue from '@/components/input/KeyValue.vue';

import MC from "@/domain/session/MC.js";

import Catelog from "@/domain/session/CanadianEnglish.js";
import UserMap from "@/domain/model/user/UserMap.js";
import EmployeeMap from "@/domain/model/employee/EmployeeMap.js";
//import Employee from "@/domain/model/employee/Employee.js";
import EmployeeListFilter from "@/domain/model/employee/EmployeeListFilter.js";
import Company from "@/domain/model/company/Company.js";

import EmployeeUtils from '@/views/portal/employees/Utils.js';
import EmployeeDetailsHeader from '@/views/portal/employees/details/EmployeeDetailsHeader.vue';
import EmployeeDetails from '@/views/portal/employees/details/EmployeeDetails.vue';

import ConfirmRemove from "@/views/portal/employees/remove/ConfirmRemoveEmployee.vue";
import ConfirmRestore from "@/views/portal/employees/remove/ConfirmRestoreEmployee.vue";

import Box from "@/portals/shared/library/box/Box.vue";
import Row from "@/portals/shared/library/box/Row.vue";
import Column from "@/portals/shared/library/box/Column.vue";

import Button from "@/portals/shared/library/button/Button.vue";
import TitleRow from "@/portals/shared/library/title/TitleRow.vue";
import LoadingRow from "@/portals/shared/library/loading/LoadingRow.vue";

import Menu from "./Menu.vue";

export default {
  name: 'portal-customer-operator-employee-file',
  components: {
    Button,
    EmployeeDetailsHeader,
    EmployeeDetails,
    ConfirmRemove,
    ConfirmRestore,
    ErrorRow,
    Menu,
    ProgressRow,
    SuccessRow,
    KeyValue,
    Box, Row, Column,
    TitleRow, LoadingRow,
  },
  props: {
    routeEmployeeEdit: { type: String, default: ConstUtils.ROUTES.EMPLOYEE.EDIT },
    routeEmployeeInvite: { type: String, default: ConstUtils.ROUTES.EMPLOYEE.INVITE },
    routeEmployeeAdd: { type: String, default: ConstUtils.ROUTES.EMPLOYEE.ADD },
    isAdmin: { type: Boolean, default: false },
    isAdminList: { type: Boolean, default: false },
    query: { type: Object, default: null },
  },
  data() {
    return {
      error: null,
      showConfirmRemove: false,
      showConfirmRestore: false,
      removeEmployee: null,
      page: 0,
      displayProgress: false,
      message: null,
      showSessionTimeoutDialog: false,
      
      isLoading: false,
      loadingMessage: "",
      users: [],
      stateFilter: this.isAdmin ? 'all' : 'active',
      catelog: new Catelog(),
      catelogKeys: Catelog.KEYS,
      total: 0,
      pageSize: 10,
      pageNumber: 0,
      searchFor: null,
      clear: false,
      MC: new MC(),
    };
  },
  computed: {
    ...mapGetters([
      'auth_user',
      'auth_client',
      'employees_found',
      'features_store',
      'users_store',
      'auth_connected',
      'auth_socket_status',
      'signin_event',
      'domain',
      //
    ]),
    max: function () {
      var t = (this.pageNumber + 1) * this.pageSize;
      return this.total < t ? this.total : t;
    },
    min: function () {
      if (this.total == 0) {
        return 0;
      }
      return (this.pageNumber * this.pageSize) + 1;
    },
    company: function () {
      if (this.domain) {
        return this.domain.session().company();
      }
      return new Company(this.domain);
    },
    hasFeatureSso: function () {
      if (this.domain) {
        return this.company.features().find().allowsSso();
      }
      return false;
    },
  },
  watch: {
    auth_connected() {
      ConnectionUtils.sendSignInEvent(this);
    },
    auth_socket_status() {
      ConnectionUtils.displayStatus(this);
    },
    signin_event() {
      ConnectionUtils.createDomainSession(this);
    }
  },
  mounted: function () {
    ConnectionUtils.ensureConnection(this);
  },
  methods: {
    ...mapActions([
      'AuthStorage_updateCompany',
      'addListenerAction',
      'removeListenerAction',
      'updateDomain',
    ]),
    start: function () {
      this.isLoading = false;
      this.loadingMessage = "";
      if (this.$route.query.status) {
        this.stateFilter = this.$route.query.status;
      }
      this.sendInitialRequest();
    },

    callback: function (msg) {
      this.loadingMessage = msg;
    },

    isEven: function (position) {
      return StringUtils.isEven(position);
    },

    decode: function (value) {
      return ContentUtils.unescape(value);
    },

    add: function () {
      var params = {
        id: this.company.id(),
      };
      this.$router.push({
        name: this.routeEmployeeAdd,
        params: params,
      });
    },

    invite: function () {
      var params = {
        id: this.company.id(),
      };
      this.$router.push({
        name: this.routeEmployeeInvite,
        params: params,
      });
    },

    edit: function (user) {
      if (!user) {
        return;
      }
      var params = {
        id: user[ConstUtils.FIELDS.ID],
      };
      this.$router.push({
        name: this.routeEmployeeEdit,
        params: params,
      });
    },

    resend: function (user) {
      if (!user) {
        return;
      }
      this.displayProgress = true;
      var event = this.domain.events().employeeResend(user);
      event.send(this.resendListener);
    },

    resendListener: function (event) {
      this.displayProgress = false;
      window.scrollTo(0, 0);
      if (event.error()) {
        this.error = event.error();
      } else {
        this.message = "User has been successfully reinvited!";
      }
    },

    remove: function (user) {
      if (!user) {
        return;
      }
      this.showConfirmRemove = true;
      this.removeEmployee = user;
    },

    restore: function (user) {
      if (!user) {
        return;
      }
      this.showConfirmRestore = true;
      this.removeEmployee = user;
    },

    display: function (user) {
      if (!user) {
        return;
      }
      var params = {
        id: user.employee[ConstUtils.FIELDS.ID],
      };
      this.$router.push({
        name: ConstUtils.ROUTES.EMPLOYEE.DETAILS,
        params: params,
      });
    },

    license: function (user) {
      var params = {
        id: user.employee[ConstUtils.FIELDS.ID],
      };
      this.$router.push({
        name: ConstUtils.ROUTES.EMPLOYEE.LICENSE,
        params: params,
      });
    },

    closeConfirmed: function () {
      this.removeEmployee = null;
      this.showConfirmRemove = false;
      this.showConfirmRestore = false;
      this.refresh();
    },

    closeRejected: function () {
      this.removeEmployee = null;
      this.showConfirmRemove = false;
      this.showConfirmRestore = false;
    },

    refresh: function () {
      this.loadUsersByFilter();
    },

    isActiveOrInvited: function (user) {
      if (!user.employee) {
        return true;
      }
      return user.employee.state === 'active' ||
        user.employee.state === 'invited';
    },

    isEmployeeInvited: function (user) {
      if (user.employee) {
        return user.employee.state === 'invited'
      }
      return true;
    },
    employeeId: function (user) {
      if (user.employee) {
        return user.employee['@rid'];
      }
      return null;
    },
    isFilterState(state) {
      return this.stateFilter === state;
    },

    setFilterState(state) {
      this.stateFilter = state;
      this.pageNumber = 0;
      this.loadUsersByFilter();
    },

    sendInitialRequest: function () {
      this.loadUsersByFilter();
    },

    loadUsersByFilter: function () {
      if (!this.domain || !this.domain.session()) {
        return;
      }
      this.users = [];
      this.isLoading = true;
      this.loadingMessage = this.MC.User.Status.Loading.value();
      var filter = new EmployeeListFilter();
      filter
        .withPagination(this.pageSize, this.pageNumber)
        .withState(this.stateFilter)
        .done();

      filter
        .withCompany(this.domain.session().company().id())
        .done();

      if (!StringUtils.isEmpty(this.searchFor)) {
        filter
          .withSearchText(this.searchFor)
          .done()
      }

      var event = this.domain.events().employees().list(filter);
      if (this.isAdmin) {
        event.with("signatures", true);
      }
      event.send(this.employeeListListener);
    },

    employeeListListener: function (event) {
      if (event.error()) {
        this.error = event.error();
      } else {
        var payload = event.payload();
        var userMap = payload.valuesFor(UserMap.MODEL_NAME);
        var company = this.domain.session().company();
        var companyId = company.id();
        var employeeMapData = payload.valuesFor(EmployeeMap.MODEL_NAME);
        var employees = new EmployeeMap(this.domain, employeeMapData); 
        if (!company.isNew()) {
          employees = this.domain.employees().findByCompany(companyId);
        }
        
        this.total = payload.count();
        
        this.update(userMap, employees);
      }
      this.isLoading = false;
    },
    
    update: function (userMap, employees) {
      var employeeList = employees.list();
      for (var i = 0; i < employeeList.length; i++) {
        var employee = employeeList[i];
        var user = employee.user();
        var userData = userMap.get(user.id());
        if (userData) {
          userData['employee'] = employee.data;
        }
      }
      if (userMap) {
        var list = Object.values(userMap.data);
        this.users = list.sort(EmployeeUtils.SortByFirst);
      }
    },
    previousPage: function () {
      if (this.canPreviousPage()) {
        this.pageNumber--;
        this.loadUsersByFilter();
      }
    },

    canPreviousPage: function () {
      return this.pageNumber > 0;
    },
    canNextPage: function () {
      return this.total > (this.pageSize * this.pageNumber) + this.pageSize;
    },

    nextPage: function () {
      if (this.canNextPage()) {
        this.pageNumber++;
        this.loadUsersByFilter();
      }
    },
    startSearch: function () {
      this.loadUsersByFilter();
    },
    clearSearch: function () {
      this.searchFor = null;
      this.clear = true;
      this.loadUsersByFilter();
    },
    searchValue: function (value) {
      this.clear = false;
      this.searchFor = value['value'];
    },
    runAction: function (action) {
      if (action === 'refresh') {
        this.loadUsersByFilter();
      }
    },
  },

};
</script>
